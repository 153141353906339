/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeE from "../../templates/PageTypeE"

import TitleImg from "../../images/products-paas-for-business-central.png"

/***************************************************
  Page Data
***************************************************/

const summaryInfo = () => {
  return (
    <div className="industries-and-finance summary-info">
      <div>
        <h2 style={{ marginBottom: "4rem" }}>
          Reliable and Secure PaaS Software Hosting Service
        </h2>
        <p>
          Platform-as-a-Service or PaaS is a method of delivering software over
          the Internet. It is an on-demand software service where a PaaS vendor
          hosts the application and the data in a central location and provides
          end-users access to the software remotely. PaaS is very beneficial to
          small and mid-sized businesses because such businesses have the need
          for technology but that doesn’t have the capital to invest in
          software, server hardware and other infrastructures. PaaS can be seen
          as an alternative hosting option for Microsoft Dynamics 365 Business
          Central. Users rent the software and access web-based Microsoft
          Dynamics 365 over the Internet from anywhere in the world. With PaaS,
          businesses do not need to worry about maintenance and infrastructure,
          such as installation, set-up, server, and software. Internet is the
          only infrastructure an end user will need to run Microsoft Dynamics
          365.
        </p>
      </div>
      <div>
        <h2 style={{ marginBottom: "4rem" }}>Pay-as-you-go Model</h2>
        <p>
          Traditionally, users own the software and they are required to pay for
          the Dynamics NAV package and license. PaaS, on the other hand, lets
          end-users access Dynamics 365 at a monthly subscription price. This
          pay-as-you-go model is one of the greatest advantages of PaaS. At
          SimCrest, you pay a one-time startup fee plus a low monthly fee for
          fully functional Dynamics 365 including server space and licensing
          from the time of Go-Live. Simply put, the recurring subscription fee
          is on a per user basis and it includes the cost of hardware, software,
          enhancement fee, licensing, IT support, maintenance, and backups.
        </p>
      </div>
    </div>
  )
}

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="PaaS for Business Central"
      description="Platform-as-a-Service or PaaS is a method of delivering software over the Internet."
      titleImg={TitleImg}
      summaryInfo={summaryInfo}
      infoList={[
        {
          title: "Lowered Upfront Cost",
          text: `The state-of-the-art technology is what every organization wants. However, the upfront investment may be a cost barrier to many businesses, especially small and mid-sized organizations. Since no additional hardware is needed, and payment is made monthly, businesses can save a huge initial cost. This is a cost savings, which can be reallocated to business operations and used to fund business growth. Having no upfront investment in either hardware or software allows PaaS to relieve a company’s financial situation and helps to enhance the cash flow.`,
        },
        {
          title: "Less Need of Internal IT Staff and Support",
          text: `By outsourcing software to a PaaS provider, organizations would need fewer internal IT personnel. Instead of assisting user support and maintaining aging infrastructure, internal IT personnel can concentrate on improving the day-to-day technical operations and making strategic decisions.`,
        },
        {
          title: "Peace of Mind",
          text: `Some businesses may lack internal IT staff to support software and some may feel the maintenance of IT infrastructure is complex and time-consuming. However, as a PaaS provider hosts the software, they handle all the tasks, including server, server support, licensing, maintenance, data backups, and on-going IT support, for you. The PaaS model leaves you peace of mind and allows you to focus more on core competencies to run your business.`,
        },
        {
          title: "True Flexibility",
          text: `PaaS enables you to save more by giving you the flexibility to adjust the total number of user licensing. Since subscription fee is charged monthly based on per user, you can easily change the number of users every month to fit personnel situations and avoid the expenditure of unused licensing.`,
        },
      ]}
    />
  )
}
